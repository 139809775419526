import dynamic from 'next/dynamic';

import Box from 'humanity/primitives/box';
import {
  reviewDefaultProps,
  reviewProps,
} from 'humanity/components/review/review.types.js';
import Image from 'humanity/primitives/image';
import Flex from 'humanity/primitives/flex';
import Text from 'humanity/primitives/text';
import Rating from 'humanity/components/rating';

const Dripicon = dynamic(() => import('humanity/components/dripicon'));

const Review = ({ rating, ratingColor, icon, image, copy, ...rest }) => (
  <Box
    sx={{
      width: 'fit-content',
      bg: 'white',
      // using theme values is hard I guess
      borderRadius: '10px',
      px: 3,
      py: 2,
    }}
    {...rest}
  >
    <Flex alignItems="center" gap="12px">
      {/* TODO: add rating component */}
      <Flex alignItems="center" gap={2}>
        {rating && <Rating value={rating} color={ratingColor} size={21} />}
        {icon && !image && <Dripicon size={18} {...icon} />}
        {image && (
          <Image
            alt={image.altText}
            src={image.image?.url}
            width={image.width}
            height={image.height}
          />
        )}
      </Flex>
      {!!copy?.length && (
        <Text pt="1px" variant="labelSmall" fontWeight="bold">
          {copy}
        </Text>
      )}
    </Flex>
  </Box>
);

Review.propTypes = {
  ...reviewProps,
};

Review.defaultProps = {
  ...reviewDefaultProps,
};

export default Review;
