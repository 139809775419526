import styled from 'styled-components';
import { css } from '@styled-system/css';

import { headingWithHighlightProps } from 'humanity/components/headingWithHighlight/headingWithHighlight.types.js';
import Heading from 'humanity/primitives/heading';

const Highlight = styled.span((props) =>
  css({
    color: props.color,
  })
);

const HeadingWithHighlight = ({
  heading,
  highlight,
  color = 'blue100',
  highlightColor = 'teal100',
  ...rest
}) => {
  const highlightIndex = heading?.indexOf(highlight);
  return (
    <Heading as="h1" variant={['h3', null, 'h2', 'h1']} color={color} {...rest}>
      {!highlight?.length && (
        /* eslint-disable-next-line react/no-danger */
        <span dangerouslySetInnerHTML={{ __html: heading }} />
      )}
      {!!highlight?.length && (
        <>
          <span
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: heading.substring(0, highlightIndex),
            }}
          />
          <Highlight color={highlightColor}>{highlight}</Highlight>
          <span
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: heading.substring(highlightIndex + highlight.length),
            }}
          />
        </>
      )}
    </Heading>
  );
};

HeadingWithHighlight.propTypes = {
  ...headingWithHighlightProps,
};

export default HeadingWithHighlight;
