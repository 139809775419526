import styled, { keyframes, css } from 'styled-components';
import systemCss from '@styled-system/css';
import { ComponentProps, FC } from 'react';

import { Theme } from '../../../utils/theme.v2';

import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';

const bounceDelay = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
`;

const animation = css`
  animation: ${bounceDelay} 1.4s infinite ease-in-out both;
`;

const StyledBounce = styled(Box)(({ color }) =>
  systemCss({
    width: 18,
    height: 18,
    bg: color,
    borderRadius: 'circle',
  })
);

const Bounce = (props) => <StyledBounce css={animation} {...props} />;

const Bounce1 = styled(Bounce)(
  systemCss({
    mx: 2,
    animationDelay: '-0.32s',
  })
);

const Bounce2 = styled(Bounce)(
  systemCss({
    animationDelay: '-0.16s',
  })
);

const Loading: FC<
  ComponentProps<typeof Flex> & {
    color?: keyof Theme['colors'];
  }
> = ({ color = 'blue100', ...props }) => (
  <Flex
    data-testid="Loading"
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
      mt: 5,
      width: 70,
    }}
    {...props}
  >
    <Bounce color={color} />
    <Bounce1 color={color} />
    <Bounce2 color={color} />
  </Flex>
);

Loading.displayName = 'Loading';

export default Loading;
