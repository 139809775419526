import PropTypes from 'prop-types';

export const ratingProps = {
  value: PropTypes.number.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

export const ratingDefaultProps = {
  size: 24,
  color: '#0224AD',
};

export const ratingType = PropTypes.shape({
  ...ratingProps,
});
