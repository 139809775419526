import PropTypes from 'prop-types';

import { dripiconType } from 'humanity/components/dripicon/dripicon.types';
import { contentfulMediaType } from 'types/common/contentfulMedia.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const reviewProps = {
  rating: PropTypes.number,
  icon: dripiconType,
  image: contentfulMediaType,
  copy: PropTypes.string.isRequired,
};

export const reviewDefaultProps = {
  rating: null,
  icon: null,
  image: null,
};

export const reviewType = PropTypes.shape({
  sys: contentfulSysType,
  ...reviewProps,
});
