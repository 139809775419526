import PropTypes from 'prop-types';

import { signupStepsContainerType } from 'humanity/sections/signupStepsContainer';

export const fastTrackMastheadFormProps = {
  form: signupStepsContainerType.isRequired,
  variant: PropTypes.oneOf(['dark', 'light']),
};

export const fastTrackMastheadFormType = PropTypes.shape({
  ...fastTrackMastheadFormProps,
});
