import { signupStepSimplifiedFragment } from 'humanity/components/signupStep';

/**
 * @type {String}
 */
export const fastTrackMastheadFormFragment = `
  ... on SignupStepsContainer {
    sys {
      id
    }
    hubspotFormId
    hubspotSandboxFormId
    salesforceCampaignId
    stepsCollection(limit: 1) {
      items {
        ${signupStepSimplifiedFragment}
      }
    }
  }
`;
