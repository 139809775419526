import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import styled, { ThemeProvider } from 'styled-components';
import { variant as styledVariant } from 'styled-system';
import css from '@styled-system/css';
import { BLOCKS } from '@contentful/rich-text-types';

import Container from 'humanity/primitives/container';
import Section from 'humanity/primitives/section';
import { simpleMastheadProps } from 'humanity/sections/simpleMasthead/simpleMasthead.types.js';
import Flex from 'humanity/primitives/flex';
import SimpleRichText from 'humanity/components/simpleRichText';
import Text from 'humanity/primitives/text';
import Cta from 'humanity/components/cta';
import Review from 'humanity/components/review';
import Image from 'humanity/primitives/image';
import Box from 'humanity/primitives/box';
import Grid from 'humanity/primitives/grid';
import { useViewport } from 'hooks/useViewport';
import { contentfulFormType } from 'humanity/components/contentfulForm/contentfulForm.types';
import { signupStepsContainerType } from 'humanity/sections/signupStepsContainer';
import { HeadingWithHighlight } from 'humanity/components/headingWithHighlight';
import Disclosures from 'humanity/components/disclosures';
import { theme } from 'utils/theme.v2';

const LeadForm = dynamic(() => import('humanity/components/leadForm'));
const FastTrackMastheadForm = dynamic(() =>
  import('humanity/components/fastTrackMastheadForm').then(
    (mod) => mod.FastTrackMastheadForm
  )
);

const Form = ({ form, ...rest }) => (
  <>
    {form.__typename === 'Form' && <LeadForm form={form} {...rest} />}
    {form.__typename === 'SignupStepsContainer' && (
      <FastTrackMastheadForm form={form} {...rest} />
    )}
  </>
);

Form.propTypes = {
  form: PropTypes.oneOf([contentfulFormType, signupStepsContainerType]).isRequired,
};

const StyledSection = styled(Section)(
  styledVariant({
    variants: {
      blue100: {
        bg: 'blue100',
      },
      brightBlue10: {
        bg: 'brightBlue10',
      },
      tealGradient: {
        backgroundImage: theme.gradients.tealTeal,
      },
      white: {
        bg: 'white',
      },
      yellow10: {
        bg: 'yellow10',
      },
    },
  })
);

const CenteredGrid = styled(Box)(
  css({
    display: 'grid',
    gridTemplateColumns: ['auto', null, '1fr 1fr', '1fr 476px 1fr', '1fr 574px 1fr'],
    gridTemplateRows: 'auto',
  })
);

const CenteredContainer = styled(Container)(
  css({
    position: 'relative',
  })
);

const CenteredCopyNoImages = styled(Flex)(
  css({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: ['left', null, 'center'],
    mx: 'auto',
    width: 574,
    maxWidth: '100%',
    py: [7, null, null, 8],
    textAlign: ['left', null, 'center'],
  })
);

const CenteredCopy = styled(Flex)(({ hasReviews }) =>
  css({
    gridColumn: ['auto', null, '1', '2'],
    gridRow: ['auto', null, '1'],
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: ['left', null, null, 'center'],
    flexBasis: 574,
    flexShrink: 0,
    mx: 'auto',
    maxWidth: '100%',
    pt: [7, null, null, 8],
    pb: hasReviews ? 0 : [7, null, null, 8],
    textAlign: ['left', null, null, 'center'],
  })
);

const CenteredReviews = styled(Flex)(({ hasImages }) =>
  css({
    gridRow: ['auto', null, null, '2'],
    gridColumn: ['auto', null, null, '2'],
    flexDirection: 'column',
    alignItems: hasImages
      ? ['flex-start', null, null, 'center']
      : ['flex-start', null, 'center'],
    gap: 3,
    mt: 5,
    pb: hasImages ? [7, null, null, 8] : 0,
  })
);

const CenteredLeftImageContainer = styled(Flex)(
  css({
    gridRow: ['2', null, 'auto / span 3'],
    gridColumn: ['auto', null, '2', 'auto'],
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    gap: [2, null, 5],
    height: '100%',
    pt: [3, null, 0],
  })
);

const CenteredImageOne = styled(Box)(
  css({
    alignSelf: 'flex-start',
    justifySelf: 'flex-start',
  })
);

const CenteredImageTwo = styled(Box)(
  css({
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
  })
);

const CenteredRightImageContainer = styled(Flex)(
  css({
    gridColumn: 'auto',
    gridRow: ['auto', null, null, 'auto / span 3'],
    display: ['none', null, null, 'flex'],
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  })
);

const CenteredImageThree = styled(Box)(
  css({
    display: ['none', null, null, 'block'],
  })
);

const LeftCopy = styled(Flex)(({ hasReviews }) =>
  css({
    gridColumn: ['1 / span 12', null, '1 / span 6', '1 / span 5'],
    gridRow: ['auto', null, '1'],
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    pt: [7, null, null, 8],
    pb: hasReviews ? 0 : [7, null, null, 8],
    textAlign: 'left',
  })
);

const LeftReviews = styled(Flex)(() =>
  css({
    gridColumn: ['1 / span 12', null, '1 / span 6', '1 / span 5'],
    gridRow: ['3', null, null, '2'],
    flexDirection: 'column',
    alignItems: ['flex-start', 'center', 'flex-start'],
    gap: 3,
    mt: 5,
  })
);

const LeftImageContainer = styled(Flex)(
  css({
    gridColumn: ['1 / span 12', null, '7 / span 6', '6 / span 7'],
    gridRow: ['2', null, '1 / span 3'],
    justifyContent: 'center',
    alignItems: 'center',
    gap: [2, null, 5],
    pt: [3, null, 0],
  })
);

const renderOpts = (fontColor) => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text variant="body" color={fontColor}>
        {children}
      </Text>
    ),
  },
});

const SimpleMasthead = ({
  alignment,
  variant = 'white',
  heading,
  headingHighlight = null,
  paragraph = null,
  form = null,
  cta = null,
  reviews = [],
  images = [],
  disclosures,
  ...rest
}) => {
  const { lg: isDesktop } = useViewport();
  const fontColor =
    variant === 'blue100' || variant === 'tealGradient' ? 'white' : 'blue100';
  const formVariant =
    variant === 'blue100' || variant === 'tealGradient' ? 'dark' : 'light';
  const [imageOne, imageTwo, imageThree] = images;

  return (
    <ThemeProvider theme={theme}>
      <StyledSection variant={variant} {...rest}>
        {alignment === 'center' && !images?.length && (
          <Container>
            <CenteredCopyNoImages>
              <HeadingWithHighlight
                heading={heading}
                highlight={headingHighlight}
                color={fontColor}
                highlightColor={variant === 'tealGradient' ? 'white' : 'teal100'}
              />
              {paragraph && (
                <SimpleRichText
                  mt={2}
                  content={paragraph?.json}
                  renderOptions={renderOpts(fontColor)}
                />
              )}
              {form && (
                <Form
                  form={form}
                  submitButtonProps={{
                    width: ['100%', null, null, 'auto'],
                    mx: [0, null, null, 'auto'],
                  }}
                  mt={4}
                  variant={formVariant}
                />
              )}
              {cta && !form && (
                <Cta
                  mt={5}
                  width={[1, null, 'auto']}
                  location="masthead"
                  btnSize="lg"
                  {...cta}
                />
              )}
              {!!reviews?.length && (
                <CenteredReviews>
                  {reviews.map((review) => (
                    <Review key={review.sys?.id} {...review} />
                  ))}
                </CenteredReviews>
              )}
            </CenteredCopyNoImages>
            {disclosures && <Disclosures pb={8} disclosures={disclosures} />}
          </Container>
        )}
        {alignment === 'center' && !!images?.length && (
          <CenteredContainer>
            <CenteredGrid>
              <CenteredLeftImageContainer>
                {imageOne && (
                  <CenteredImageOne>
                    <Image
                      src={imageOne.image?.url}
                      width={imageOne.width}
                      height={imageOne.height}
                      quality={100}
                      imgixParams={{ auto: 'format' }}
                      priority
                    />
                  </CenteredImageOne>
                )}
                {!isDesktop && imageTwo && (
                  <CenteredImageTwo>
                    <Image
                      src={imageTwo.image?.url}
                      width={imageTwo.width}
                      height={imageTwo.height}
                      quality={100}
                      imgixParams={{ auto: 'format' }}
                      priority
                    />
                  </CenteredImageTwo>
                )}
                {isDesktop && imageThree && (
                  <CenteredImageTwo>
                    <Image
                      src={imageThree.image?.url}
                      width={imageThree.width}
                      height={imageThree.height}
                      quality={100}
                      imgixParams={{ auto: 'format' }}
                      priority
                    />
                  </CenteredImageTwo>
                )}
              </CenteredLeftImageContainer>
              <CenteredCopy hasReviews={!!reviews?.length}>
                <HeadingWithHighlight
                  heading={heading}
                  highlight={headingHighlight}
                  color={fontColor}
                  highlightColor={variant === 'tealGradient' ? 'white' : 'teal100'}
                />
                {paragraph && (
                  <SimpleRichText
                    mt={2}
                    content={paragraph?.json}
                    renderOptions={renderOpts(fontColor)}
                  />
                )}
                {form && (
                  <Form
                    form={form}
                    submitButtonProps={{
                      width: ['100%', null, null, 'auto'],
                      mx: [0, null, null, 'auto'],
                    }}
                    mt={4}
                    variant={formVariant}
                  />
                )}
                {cta && !form && (
                  <Cta
                    mt={5}
                    width={[1, null, 'auto']}
                    location="masthead"
                    btnSize="lg"
                    {...cta}
                  />
                )}
              </CenteredCopy>
              {!!reviews?.length && (
                <CenteredReviews hasImages>
                  {reviews.map((review) => (
                    <Review key={review.sys?.id} {...review} />
                  ))}
                </CenteredReviews>
              )}
              <CenteredRightImageContainer>
                {!isDesktop && imageThree && (
                  <CenteredImageThree>
                    <Image
                      src={imageThree.image?.url}
                      width={imageThree.width}
                      height={imageThree.height}
                      quality={100}
                      imgixParams={{ auto: 'format' }}
                      priority
                    />
                  </CenteredImageThree>
                )}
                {isDesktop && imageTwo && (
                  <CenteredImageThree>
                    <Image
                      src={imageTwo.image?.url}
                      width={imageTwo.width}
                      height={imageTwo.height}
                      quality={100}
                      imgixParams={{ auto: 'format' }}
                      priority
                    />
                  </CenteredImageThree>
                )}
              </CenteredRightImageContainer>
            </CenteredGrid>
            {disclosures && <Disclosures mt={6} pb={8} disclosures={disclosures} />}
          </CenteredContainer>
        )}
        {alignment === 'left' && (
          <Container>
            <Grid pb={disclosures ? 0 : [(7, null, null, 8)]}>
              <LeftCopy hasReviews={!!reviews?.length}>
                <HeadingWithHighlight
                  heading={heading}
                  highlight={headingHighlight}
                  color={fontColor}
                  highlightColor={variant === 'tealGradient' ? 'white' : 'teal100'}
                />
                {paragraph && (
                  <SimpleRichText
                    mt={2}
                    content={paragraph?.json}
                    renderOptions={renderOpts(fontColor)}
                  />
                )}
                {form && (
                  <Form
                    form={form}
                    submitButtonProps={{ width: ['100%', null, null, 'auto'] }}
                    mt={4}
                    variant={formVariant}
                  />
                )}
                {cta && !form && (
                  <Cta
                    mt={5}
                    width={[1, null, 'auto']}
                    location="masthead"
                    btnSize="lg"
                    {...cta}
                  />
                )}
              </LeftCopy>
              {!!reviews?.length && (
                <LeftReviews>
                  {reviews.map((review) => (
                    <Review key={review.sys?.id} {...review} />
                  ))}
                </LeftReviews>
              )}
              <LeftImageContainer>
                {imageOne && (
                  <Image
                    src={imageOne.image?.url}
                    width={imageOne.width}
                    height={imageOne.height}
                    quality={100}
                    imgixParams={{ auto: 'format' }}
                    priority
                  />
                )}
              </LeftImageContainer>
            </Grid>
            {disclosures && <Disclosures mt={5} pb={8} disclosures={disclosures} />}
          </Container>
        )}
      </StyledSection>
    </ThemeProvider>
  );
};

SimpleMasthead.propTypes = {
  ...simpleMastheadProps,
};

export default SimpleMasthead;
