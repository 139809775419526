export const fastTrackMastheadFormStandard = {
  __typename: 'SignupStepsContainer',
  sys: { id: '3DQeqm5SphXBci6YG1nvdA' },
  hubspotFormId: 'af3554ff-20ee-4ca3-92ab-58bb6df7cd6e',
  hubspotSandboxFormId: '63bb8787-62c0-4acd-b82c-32d780bb8ecc',
  salesforceCampaignId: '7018X000001mnHFQAY',
  fastTrackPayrolls: ['Gusto'],
  stepsCollection: {
    items: [
      {
        sys: { id: 'LmRnyJe4aJwLlX3HqMVuw' },
        title: 'Tell us more about you',
        subtitle: null,
        bottomText: null,
        inputsCollection: {
          items: [
            {
              sys: { id: '6hWMcoyJxhcLHm9Y4HCuK5' },
              type: 'email',
              label: 'Work email',
              required: true,
              fieldName: 'email',
              placeholder: null,
              width: 'full',
              autocompleteValue: 'email',
              optionsCollection: { items: [] },
            },
            {
              sys: { id: '6SlZTlEjGzJtE8e5Ieonfe' },
              type: 'select',
              label: 'Your role',
              required: true,
              fieldName: 'role',
              placeholder: null,
              width: 'full',
              autocompleteValue: 'organization-title',
              optionsCollection: {
                items: [
                  {
                    sys: { id: '7hIDupuo2PjynuJuwqeInY' },
                    label: 'CEO/Owner',
                    value: 'CEO/Owner',
                  },
                  {
                    sys: { id: '7LWxkcnelQzVNinzRpkXQ0' },
                    label: 'Office Manager',
                    value: 'Office Manager',
                  },
                  {
                    sys: { id: '32huYkzZmBvO21dJQaPmuI' },
                    label: 'Operations',
                    value: 'Operations',
                  },
                  {
                    sys: { id: '3ArYIwV26FCfa5F4i1jEZq' },
                    label: 'Finance',
                    value: 'Finance',
                  },
                  {
                    sys: { id: 'BDwYT6pSD3WKL9wbcGANj' },
                    label: 'Human Resources/Recruiting',
                    value: 'Human Resource/Recruiting',
                  },
                  {
                    sys: { id: '7mmTCLOfINuhUryT2DpFwE' },
                    label: 'Other',
                    value: 'Other',
                  },
                  {
                    sys: { id: '6KZgp4QgdecbXwoQMfbFBp' },
                    label: 'Employee / Trying to Login',
                    value: 'Employee / Trying to Login',
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
};
