import { Document } from '@contentful/rich-text-types';

import { ContentfulSysInterface } from '../../../types/common/contentfulSys.types';

import { InputFragment, inputFragment } from 'humanity/components/formikInput/input.fragment';

export const formFragment = (withImage = false) => `
  ... on Form {
    sys {
      id
    }
    title
    subtitle
    hubspotFormId
    hubspotSandboxFormId
    salesforceCampaignId
    formType
    disclosures {
      json
    }
    submitButtonText
    submitButtonVariant
    inputsCollection(limit: 10) {
      items {
        ${inputFragment({ withImage })}
      }
    }
  }
`;

export type FormFragment = ContentfulSysInterface & {
  title?: string;
  subtitle?: string;
  hubspotFormId?: string;
  hubspotSandboxFormId?: string;
  salesforceCampaignId?: string;
  formType?: 'Webinar' | 'Guide' | 'Calculator' | 'Newsletter' | 'Signup';
  disclosures?: {
    json: Document;
  };
  submitButtonText: string;
  submitButtonVariant: 'primary' | 'secondary' | 'outline';
  inputsCollection?: {
    items: InputFragment[];
  };
}
