/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';

import { ratingProps } from 'humanity/components/rating/rating.types.js';
import Flex from 'humanity/primitives/flex';
import Box from 'humanity/primitives/box';

const StarEmpty = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M10.5 2.44219L12.5007 6.89998L12.7355 7.42308L13.3056 7.48472L18.1634 8.01L14.5421 11.2903L14.1171 11.6753L14.2347 12.2365L15.2363 17.0189L10.9974 14.5885L10.5 14.3033L10.0026 14.5885L5.76374 17.0189L6.76533 12.2365L6.88286 11.6753L6.45792 11.2903L2.83657 8.01L7.69444 7.48472L8.26448 7.42308L8.49926 6.89998L10.5 2.44219Z"
      fill="white"
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

StarEmpty.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

const StarFull = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="M10.5 0L13.4131 6.49051L20.4861 7.25532L15.2134 12.0315L16.6717 18.9947L10.5 15.456L4.32825 18.9947L5.78656 12.0315L0.513906 7.25532L7.58694 6.49051L10.5 0Z"
      fill={color}
    />
  </svg>
);

StarFull.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

const MAX_RATING = 5;

const Rating = ({ value, color, size, ...rest }) => (
  <Flex alignItems="center" lineHeight={0} aria-label={`${value} stars`} {...rest}>
    {[...new Array(MAX_RATING)].map((a, idx) => {
      if (idx > Math.floor(value)) {
        return (
          <Box key={idx}>
            <StarEmpty color={color} size={size} />
          </Box>
        );
      }
      return (
        <Box sx={{ position: 'relative' }} key={idx}>
          <Box
            sx={{
              width: idx < Math.floor(value) ? '100%' : `${(value % 1) * 100}%`,
              overflow: 'hidden',
              position: 'absolute',
            }}
          >
            <StarFull color={color} size={size} />
          </Box>
          <Box>
            <StarEmpty color={color} size={size} />
          </Box>
        </Box>
      );
    })}
  </Flex>
);

Rating.propTypes = {
  ...ratingProps,
};

export default Rating;
