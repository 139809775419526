import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';
import { dripiconFragment } from 'humanity/components/dripicon/dripicon.fragment';

/**
 * @type {String}
 */
export const reviewFragment = `
  ... on Review {
    sys {
      id
    }
    rating
    ratingColor
    icon {
      ${dripiconFragment}
    }
    image {
      ${imageFragment}
    }
    copy
  }
`;
