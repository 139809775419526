import { imageFragment } from 'legacy/graphql/fragments/components/imageFragment';
import { formFragment } from 'humanity/components/contentfulForm/form.fragment';
import { ctaFragment } from 'humanity/components/cta/cta.fragment';
import { fastTrackMastheadFormFragment } from 'humanity/components/fastTrackMastheadForm';
import { reviewFragment } from 'humanity/components/review/review.fragment';

/**
 * @type {String}
 */
export const simpleMastheadFragment = `
  ... on SimpleMasthead {
    __typename
    alignment
    variant
    heading
    headingHighlight
    paragraph {
      json
    }
    form {
      __typename
      ${formFragment()}
      ${fastTrackMastheadFormFragment}
    }
    cta {
      ${ctaFragment}
    }
    reviewsCollection(limit: 3) {
      items {
        ${reviewFragment}
      }
    }
    imagesCollection(limit: 3) {
      items {
        ${imageFragment}
      }
    }
    disclosures {
      json
    }
  }
`;
