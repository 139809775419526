/**
 * @type {String}
 */
export const dripiconFragment = `
  ... on Dripicon {
    sys {
      id
    }
    title
    icon
    url
  }
`;
