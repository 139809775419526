import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { ctaType } from 'humanity/components/cta/cta.types';
import { reviewType } from 'humanity/components/review/review.types';
import { contentfulImageType } from 'types/common/contentfulImage.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';

export const simpleMastheadProps = {
  alignment: PropTypes.oneOf(['center', 'left']).isRequired,
  variant: PropTypes.string,
  heading: PropTypes.string.isRequired,
  headingHighlight: PropTypes.string,
  paragraph: simpleRichTextType,
  cta: ctaType,
  reviews: PropTypes.arrayOf(reviewType),
  images: PropTypes.arrayOf(contentfulImageType),
  disclosures: simpleRichTextType,
};

export const simpleMastheadType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...simpleMastheadProps,
});
